import React, { Component } from 'react';
// import parse from 'parse-link-header';
import { parseLinkHeader } from '@web3-storage/parse-link-header';

import Config from '../../config';
import i18next from '../../i18n';
import UserContext from './Context';
import customerApi from '../../api/customer';
import { setSessionActive, setSessionInactive } from 'utils/session';

const AmazonCognitoIdentity = require('amazon-cognito-identity-js');
const poolData = {
  UserPoolId: Config.cognito.USER_POOL_ID,
  ClientId: Config.cognito.APP_CLIENT_ID,
  Storage: new AmazonCognitoIdentity.CookieStorage({
    // wildcard domain for cross-subdomain cookies
    domain: Config.cookieDomain,
    secure: process.env.NODE_ENV === 'production',
  }),
};
const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

class Provider extends Component {
  state = {
    appId: '',
    sId: '',
    userId: '',
    auth: {},
    appLoading: false,
    schemaUrls: {},
    isError: false,
    errMessage: '',

    /**
     * Returns schema links from the customer API.
     * Sets the schema state.
     * @returns {null} null
     */
    getSchemaLinks: async () => {
      try {
        const { schemaUrls } = this.state;
        const isSchemaEmpty = Object.keys(schemaUrls).length === 0;

        if (isSchemaEmpty) {
          const res = await customerApi.getSchemaUrls();

          if (res.status <= 202) {
            const header = res.headers['link'];
            const parsed = parseLinkHeader(header);
            const urlsObject = Object.entries(parsed);

            // Batch update the schema state to avoid repeated calls to setState
            const updatedSchemaUrls = { ...schemaUrls };
            urlsObject.forEach(([key, value]) => {
              updatedSchemaUrls[value.rel] = value.url;
            });

            this.setState({
              schemaUrls: updatedSchemaUrls,
              appLoading: false,
            });
          } else if (res.status >= 400) {
            throw new Error(res.statusText);
          }
        } else {
          this.setState({ appLoading: false });
        }
      } catch (e) {
        console.error('getSchemaLinks error:', e);

        this.setState({
          appLoading: false,
          isError: true,
          errMessage: 'There has been an error at getSchemaLinks.',
        });
      }
    },

    /**
     * Returns the session from the cognito user pool.
     * Sets the session state with auth info.
     * @param {Object} from - The UI location from where the request is being made, possible values: 'login', 'register'
     * @returns {null} null
     */
    getSession: async (from) => {
      try {
        let cognitoUser = userPool.getCurrentUser();

        if (cognitoUser === null)
          throw new Error('Unable to retrieve user info (getSession)), cognitoUser is null.');

        await cognitoUser.getSession((err, session) => {
          if (err) throw new Error(err);

          this.setState({
            ...this.state,
            auth: {
              activeSession: true,
              jwtToken: session.idToken.jwtToken,
            },
          });

          setSessionActive();

          switch (from) {
            case 'login':
              window.location.reload();
              break;
            case 'register':
              window.location.reload();
              break;
            case 'appMount':
              // this.state.getSchemaLinks();
              break;
            default:
              break;
          }

          return true;
        });
      } catch (e) {
        // console.log('getSession Error: ', e);

        this.setState({
          ...this.state,
          activeSession: false,
          appLoading: false,
        });

        setSessionInactive();

        return false;
      }
    },

    /**
     * Refreshes the user session.
     * @param {Object} from - The UI location from where the request is being made, possible values: 'login', 'register'
     * @returns {null} null
     */
    getRefreshedSession: async (from) => {
      try {
        let cognitoUser = userPool.getCurrentUser();

        if (cognitoUser === null) {
          if (from === 'app') return;
          else
            throw new Error(
              'Unable to retrieve user info (getRefreshedSession)), cognitoUser is null.',
            );
        }

        await cognitoUser.getSession((err, session) => {
          if (err) throw new Error(err);

          let refresh_token = session.getRefreshToken();

          cognitoUser.refreshSession(refresh_token, (e, refreshedSession) => {
            if (e) throw new Error(e);

            this.setState({
              ...this.state,
              auth: {
                activeSession: true,
                jwtToken: refreshedSession.idToken.jwtToken,
              },
            });

            setSessionActive();
          });

          return true;
        });
      } catch (e) {
        console.log('getRefreshedSession Error: ', e);

        this.setState({
          ...this.state,
          activeSession: false,
          appLoading: false,
        });

        setSessionInactive();

        return false;
      }
    },

    /**
     * Fetches the data for a particular app from the customer API.
     * @param {String} appId - The app ID to fetch data for.
     * @returns {Object} The data for the app.
     */
    getApp: async (appId) => {
      try {
        // Initializing the getSession method to prevent expired bearer token requests
        this.state.getSession();

        // Check for the appId in browser session, if present, set it to state.
        if (!appId) {
          try {
            appId = sessionStorage.getItem('appId');
          } catch (error) {
            console.log('SessionStorage Error: ', error);
          }
        }

        if (!appId || appId === 'undefined')
          throw new Error('appId is missing in the request at getApp.');

        const res = await customerApi.getApp(appId, this.state.auth.jwtToken);

        if (res.status <= 202) return res.data;
        else if (res.status >= 400) throw new Error(res.statusText);
      } catch (e) {
        console.log('getApp error: ', e);
        return;
      }
    },

    /**
     * Ends the user session with the cognito user pool.
     * @returns {null} null
     */
    endSession: (callback) => {
      try {
        let cognitoUser = userPool.getCurrentUser();

        if (cognitoUser === null)
          throw new Error('Unable to retrieve user info (endSession)), cognitoUser is null.');

        cognitoUser.signOut();

        this.setState({
          ...this.state,
          auth: {},
        });

        try {
          // Clear all data from localStorage & sessionStorage.
          sessionStorage.clear();
          localStorage.clear();
          setSessionInactive();
        } catch (e) {
          console.log('LocalStorage/SessionStorage Error');
        }

        setTimeout(callback, 100);
      } catch (e) {
        console.log('endSession error: ', e);
      }
    },

    /**
     * Authenticate the user with the cognito user pool.
     * @param email - The user's email.
     * @param password - The user's password.
     * @param setErrors - A callback function to set the errors in the component state.
     * @param setButtonLoading - A callback function to set the button loading state in the component state.
     * @param callBack - A callback function to be called after the user is authenticated.
     * @param redirect - A callback function to redirect a user after the authentication.
     * @returns {null} null
     */
    authenticate: (email, password, setErrors, setButtonLoading, callback, redirect) => {
      try {
        let authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
          Username: email,
          Password: password,
        });

        let cognitoUser = new AmazonCognitoIdentity.CognitoUser({
          Username: email,
          Pool: userPool,
          Storage: new AmazonCognitoIdentity.CookieStorage({
            // wildcard domain for cross-subdomain cookies
            domain: Config.cookieDomain,
            secure: process.env.NODE_ENV === 'production',
          }),
        });

        cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: (result) => {
            this.setState({
              ...this.state,
              auth: {
                activeSession: true,
                jwtToken: result.idToken.jwtToken,
              },
            });

            setSessionActive();

            if (callback) {
              if (callback === 'register') this.state.getSession('register');
              else callback('login');
            }

            return true;
          },

          onFailure: (err) => {
            const authError = err.message;
            setButtonLoading(false);

            switch (err.code) {
              case 'UserNotConfirmedException':
                setErrors((errors) => ({
                  ...errors,
                  email: {
                    invalid: true,
                    error: i18next.t('authErrors|' + err.code, {
                      nsSeparator: '|',
                    }),
                  },
                }));
                break;
              case 'UserNotFoundException':
                setErrors((errors) => ({
                  ...errors,
                  email: {
                    invalid: true,
                    error: i18next.t('authErrors|' + err.code, {
                      nsSeparator: '|',
                    }),
                  },
                }));
                break;
              case 'NotAuthorizedException':
                setErrors((errors) => ({
                  ...errors,
                  email: {
                    invalid: true,
                    error: i18next.t('authErrors|' + err.code, {
                      nsSeparator: '|',
                    }),
                  },
                  password: {
                    invalid: true,
                    error: '',
                  },
                }));
                break;
              case 'ResourceNotFoundException':
                setErrors((errors) => ({
                  ...errors,
                  masterError: i18next.t('authErrors|' + err.code, {
                    nsSeparator: '|',
                  }),
                  masterInvalid: true,
                }));
                break;
              case 'PasswordResetRequiredException':
                callback();
                redirect();
                break;
              case 'LimitExceededException':
                setErrors((errors) => ({
                  ...errors,
                  masterError: i18next.t('authErrors|' + err.code, {
                    nsSeparator: '|',
                  }),
                  masterInvalid: true,
                }));
                break;
              default:
                setErrors((errors) => ({
                  ...errors,
                  masterError: authError,
                  masterInvalid: true,
                }));
                break;
            }

            setErrors((errors) => ({
              ...errors,
              masterError: authError,
              masterInvalid: true,
            }));

            return;
          },

          newPasswordRequired: (authenticationDetails) => {
            delete authenticationDetails.email_verified;
            delete authenticationDetails.phone_number_verified;

            cognitoUser.completeNewPasswordChallenge(password, authenticationDetails, this);
          },
        });
      } catch (e) {
        console.log('authenticate error: ', e);
        return;
      }
    },

    /**
     * Fetches the data for a particular user from the customer API.
     * @returns {Object} The data for the user.
     */
    getUser: async () => {
      try {
        if (!this.state.auth.jwtToken) this.state.getSession();

        const res = await customerApi.getUser(this.state.auth.jwtToken);

        if (res.status <= 202) return res.data;
        else if (res.status >= 400) throw new Error(res.statusText);
      } catch (e) {
        console.log('getUser error: ', e);
        return;
      }
    },

    /**
     * Creates a new user in the customer API.
     * @param {Object} data
     * @returns {Number} The request http status code.
     */
    createUser: async (data) => {
      // console.log('createUser: ', data);
      try {
        const res = await customerApi.createUser(data);

        if (res.status <= 202) {
          this.setState({
            ...this.state,
            userId: res.data.id,
          });

          try {
            localStorage.removeItem('userId');
            localStorage.setItem('userId', `${res.data.id}`);
          } catch (e) {
            console.log('LocalStorage Error');
          }

          return res.status;
        } else if (res.status >= 400) throw new Error(res.statusText);
      } catch (e) {
        console.log('createUser error: ', e);
        return 400;
      }
    },

    /**
     * Creates a new application in the customer API.
     * @param {Object} data - The data for the application.
     * @returns {Number} The request http status code.
     */
    createApp: async (data) => {
      // console.log('createApp: ', data);
      try {
        // Check for the appId in browser session, if present, set it to state.
        let userId = localStorage.getItem('userId');

        if (userId && !this.state.userId) {
          this.setState({
            ...this.state,
            userId: userId,
          });
        }

        if (!userId || userId === 'undefined') throw new Error('userId is missing in the request');

        data.meta = {
          step: sessionStorage.getItem('step') ? sessionStorage.getItem('step').toString() : '9',
          userAgent: window.navigator.userAgent,
          stepLabel: sessionStorage.getItem('stepLabel')
            ? sessionStorage.getItem('stepLabel')
            : 'unknown',
        };

        // if sessionStorage has query, add it to the meta object
        if (sessionStorage.getItem('query'))
          data.meta.query = JSON.parse(sessionStorage.getItem('query'));

        data.group = 'homewise';

        const res = await customerApi.createApp(userId, data);

        if (res.status <= 202) {
          this.setState({
            ...this.state,
            appId: res.data.id,
          });
          // console.log('setItem appId', res.data.id);
          sessionStorage.setItem('appId', `${res.data.id}`);

          return res.status;
        } else if (res.status >= 400) throw new Error(res.statusText);
      } catch (e) {
        console.log('createApp error: ', e);
        return 400;
      }
    },

    /**
     * Updates an application in the customer API.
     * @param {Object} data - The data for the application.
     * @returns {Number} The request http status code.
     */
    updateApp: async (data) => {
      // console.log('updateApp: ', data);
      try {
        if (!this.state.auth.jwtToken) this.state.getSession();

        // Check for the appId in browser session, if present, set it to state.
        let appId = sessionStorage.getItem('appId');

        if (appId && !this.state.appId) {
          this.setState({
            ...this.state,
            appId: appId,
          });
        }

        if (!appId || appId === 'undefined') throw new Error('appId is missing in the request');

        data.meta = {
          step: sessionStorage.getItem('step') ? sessionStorage.getItem('step').toString() : '9',
          stepLabel: sessionStorage.getItem('stepLabel')
            ? sessionStorage.getItem('stepLabel')
            : 'unknown',
        };
        // console.log('data: ', data);

        const res = await customerApi.updateApp(appId, data, this.state.auth.jwtToken);

        if (res.status <= 202) {
          return res.status;
        } else if (res.status >= 400) throw new Error(res.statusText);
      } catch (e) {
        console.log('updateApp error: ', e);
        return 400;
      }
    },

    /**
     * Updates a user in the customer API.
     * @param {Object} data
     * @returns {Number} The request http status code.
     */
    updateUser: async (data) => {
      // console.log('updateUser: ', data);
      try {
        if (!this.state.auth.jwtToken) this.state.getSession();

        const res = await customerApi.updateUser(data, this.state.auth.jwtToken);

        if (res.status <= 202) {
          return res.status;
        } else if (res.status >= 400) throw new Error(res.statusText);
      } catch (e) {
        console.log('updateUser error: ', e);
        return 400;
      }
    },

    /**
     * Creates a signer in the customer API.
     * @param {Object} data
     * @returns {Number} The request http status code.
     */
    createSigner: async (data) => {
      // console.log('createSigner: ', data);
      try {
        if (!this.state.auth.jwtToken) this.state.getSession();

        // Check for the appId in browser session, if present, set it to state.
        let appId = sessionStorage.getItem('appId');

        if (appId && !this.state.appId) {
          this.setState({
            ...this.state,
            appId: appId,
          });
        }

        if (!appId || appId === 'undefined') throw new Error('appId is missing in the request');

        const res = await customerApi.createSigner(appId, data, this.state.auth.jwtToken);

        if (res.status <= 202) {
          this.setState({
            ...this.state,
            sId: res.data.sId,
          });

          sessionStorage.removeItem('sId');
          sessionStorage.setItem('sId', `${res.data.sId}`);

          return res.status;
        } else if (res.status >= 400) throw new Error(res.statusText);
      } catch (e) {
        console.log('createSigner error: ', e);
        return 400;
      }
    },

    /**
     * Update a signer in the customer API.
     * @param {Object} data
     * @returns {Number} The request http status code.
     */
    updateSigner: async (data) => {
      // console.log('updateSigner: ', data);
      try {
        if (!this.state.auth.jwtToken) this.state.getSession();

        // Check for the sId in browser session, if present, set it to state.
        let sId = sessionStorage.getItem('sId'),
          appId = sessionStorage.getItem('appId');

        if (sId && !this.state.sId) {
          this.setState({
            ...this.state,
            sId: sId,
          });
        }

        if (!sId || sId === 'undefined') throw new Error('sId is missing in the request');

        if (appId && !this.state.appId) {
          this.setState({
            ...this.state,
            appId: appId,
          });
        }

        if (!appId || appId === 'undefined') throw new Error('appId is missing in the request');

        const res = await customerApi.updateSigner(appId, sId, data, this.state.auth.jwtToken);

        if (res.status <= 202) {
          this.setState({
            ...this.state,
            sId: res.data.sId,
          });

          return res.status;
        } else if (res.status >= 400) throw new Error(res.statusText);
      } catch (e) {
        console.log('updateSigner error: ', e);
        return 400;
      }
    },

    /**
     * Updates both the application and the user in the customer API.
     * @param {Object} userData
     * @param {Object} appData
     * @returns {Number} The request http status code.
     */
    updateAll: async (userData, appData) => {
      // console.log('updateAll: ', userData, appData);
      try {
        appData.group = 'homewise';
        userData.group = 'homewise';

        appData.meta = {
          step: sessionStorage.getItem('step') ? sessionStorage.getItem('step').toString() : '9',
          stepLabel: sessionStorage.getItem('stepLabel')
            ? sessionStorage.getItem('stepLabel')
            : 'unknown',
        };

        const responses = await Promise.all([
          this.state.updateUser(userData),
          this.state.updateApp(appData),
        ]);
        return await Promise.all(
          responses.map(function (response) {
            return response;
          }),
        );
      } catch (e) {
        console.log('updateAll error: ', e);
        return 400;
      }
    },

    /**
     * Validates a email address.
     * @param {String} data - The email to validate.
     * @param {String} type - The type of email address to validate, possible values, 'primary', 'other'.
     * @returns {Object} data - The validation result.
     */
    checkEmail: async (data, type) => {
      try {
        const res = await customerApi.checkEmail(data, type);

        if (res.status <= 202) return res.data;
        else if (res.status === 404)
          return res.data; // This is a valid response for a non-existing email.
        else if (res.status >= 400) throw new Error(res.statusText);
      } catch (e) {
        console.log('checkEmail error: ', e);
        return;
      }
    },
  };

  /**
   * Verifies Cognito account status code entered by user.
   * @param {String} email
   * @param {String} userCode
   * @param {Function} setErrors
   * @param {Function} setButtonLoading
   * @param {Function} callback
   * @returns {null} null
   */
  verifyCode = (email, userCode, setErrors, setButtonLoading, callback) => {
    try {
      let cognitoUser = new AmazonCognitoIdentity.CognitoUser({
        Username: email,
        Pool: userPool,
        Storage: new AmazonCognitoIdentity.CookieStorage({
          // wildcard domain for cross-subdomain cookies
          domain: Config.cookieDomain,
          secure: process.env.NODE_ENV === 'production',
        }),
      });

      cognitoUser.confirmRegistration(userCode, true, (err, result) => {
        if (err) {
          const errors = {
            userCodeError: '',
            userCodeInvalid: false,
          };

          if (err.code === 'CodeMismatchException') {
            errors.userCodeError = err.message;
            errors.userCodeInvalid = true;
            setErrors({ ...errors });
          }
          if (err.code === 'NotAuthorizedException') {
            errors.userCodeError = err.message;
            errors.userCodeInvalid = true;
            setErrors({ ...errors });
          }
          setButtonLoading(false);
          return;
        } else {
          callback();
          return;
        }
      });
    } catch (e) {
      console.log('verifyCode error: ', e);
      return;
    }
  };

  /**
   * Resets Cognito account password.
   * @param {String} email
   * @param {Function} setErrors
   * @param {Function} setButtonLoading
   * @param {Function} callback
   * @param {Function} callbackOnFail
   */
  resetPassword = async (email, setErrors, setButtonLoading, callback, callbackOnFail) => {
    try {
      let cognitoUser = new AmazonCognitoIdentity.CognitoUser({
        Username: email,
        Pool: userPool,
        Storage: new AmazonCognitoIdentity.CookieStorage({
          // wildcard domain for cross-subdomain cookies
          domain: Config.cookieDomain,
          secure: process.env.NODE_ENV === 'production',
        }),
      });

      cognitoUser.forgotPassword({
        onSuccess: function (result) {},
        onFailure: function (err) {
          if (err.code === 'LimitExceededException') {
            setErrors((errors) => ({
              ...errors,
              masterError: i18next.t('authErrors|' + err.code, {
                nsSeparator: '|',
              }),
              masterInvalid: true,
            }));
          } else {
            setErrors((errors) => ({
              ...errors,
              email: {
                invalid: true,
                error: i18next.t('authErrors|' + err.code, {
                  nsSeparator: '|',
                }),
              },
            }));
          }
          setButtonLoading(false);
          callbackOnFail();
        },
        inputVerificationCode() {
          // var verificationCode = prompt("Please input verification code ", "");
          // var newPassword = prompt("Enter new password ", "");
          // cognitoUser.confirmPassword(verificationCode, newPassword, this);
          callback();
        },
      });
    } catch (e) {
      console.log('resetPassword error: ', e);
      return;
    }
  };

  /**
   * Confirms Cognito account password.
   * @param {String} email
   * @param {String} verificationCode
   * @param {String} newPassword
   * @param {Function} setErrors
   * @param {Function} setButtonLoading
   * @param {Function} callback
   * @returns {null} null
   */
  confirmPassword = async (
    email,
    verificationCode,
    newPassword,
    setErrors,
    setButtonLoading,
    callback,
  ) => {
    try {
      let cognitoUser = new AmazonCognitoIdentity.CognitoUser({
        Username: email,
        Pool: userPool,
        Storage: new AmazonCognitoIdentity.CookieStorage({
          // wildcard domain for cross-subdomain cookies
          domain: Config.cookieDomain,
          secure: process.env.NODE_ENV === 'production',
        }),
      });
      return new Promise(() => {
        cognitoUser.confirmPassword(verificationCode, newPassword, {
          onFailure(err) {
            if (err.code === 'InvalidPasswordException' || err.code === 'UserNotFoundException') {
              setErrors((errors) => ({
                ...errors,
                password: {
                  invalid: true,
                  error: i18next.t('authErrors:errors.password.pattern'),
                },
              }));
            }
            if (err.code === 'CodeMismatchException' || err.code === 'ExpiredCodeException') {
              setErrors((errors) => ({
                ...errors,
                code: {
                  invalid: true,
                  error: i18next.t('authErrors:errors.code.' + err.code),
                },
              }));
            }
            if (err.code === 'InvalidParameterException') {
              setErrors((errors) => ({
                ...errors,
                password: {
                  invalid: true,
                  error: i18next.t('authErrors:errors.password.pattern'),
                },
                code: {
                  invalid: true,
                  error: i18next.t('authErrors:errors.code.pattern'),
                },
              }));
            }
            // if (err.code === 'InvalidLambdaResponseException') {
            //   console.log(err);
            // }
            setButtonLoading(false);
          },
          onSuccess: () => {
            this.state.authenticate(email, newPassword, setErrors, setButtonLoading, callback);
            return;
          },
        });
      });
    } catch (e) {
      console.log('confirmPassword error: ', e);
      return;
    }
  };

  /**
   * Resends Cognito account verification code.
   * @param {String} email
   * @param {Function} setErrors
   * @param {Function} setButtonLoading
   * @param {Function} callback
   * @returns
   */
  resendCode = async (email, setErrors, setButtonLoading, callback) => {
    try {
      let cognitoUser = new AmazonCognitoIdentity.CognitoUser({
        Username: email,
        Pool: userPool,
        Storage: new AmazonCognitoIdentity.CookieStorage({
          // wildcard domain for cross-subdomain cookies
          domain: Config.cookieDomain,
          secure: process.env.NODE_ENV === 'production',
        }),
      });
      cognitoUser.resendConfirmationCode(function (err, result) {
        if (err) {
          // alert(err.message || JSON.stringify(err));
          const authError = err.message;
          setButtonLoading(false);
          if (err.code === 'UserNotFoundException') {
            setErrors((errors) => ({
              ...errors,
              email: {
                invalid: true,
                error: i18next.t('authErrors|' + err.code, {
                  nsSeparator: '|',
                }),
              },
            }));
          } else {
            setErrors((errors) => ({
              ...errors,
              email: {
                invalid: true,
                error: i18next.t('authErrors|' + authError, {
                  nsSeparator: '|',
                }),
              },
            }));
          }
          return;
        }
        callback();
      });
    } catch (e) {
      console.log('resendCode error: ', e);
      return;
    }
  };

  render() {
    const { children } = this.props;
    const {
      appId,
      sId,
      auth,
      schemaUrls,
      appLoading,
      isError,
      errMessage,
      getSchemaLinks,
      getSession,
      getRefreshedSession,
      getApp,
      getUser,
      endSession,
      authenticate,
      createUser,
      createApp,
      updateApp,
      updateUser,
      createSigner,
      updateSigner,
      updateAll,
      checkEmail,
    } = this.state;
    const { verifyCode, resetPassword, confirmPassword, resendCode } = this;

    return (
      <UserContext.Provider
        value={{
          appId,
          sId,
          auth,
          schemaUrls,
          appLoading,
          isError,
          errMessage,
          getSession,
          getRefreshedSession,
          getApp,
          getUser,
          endSession,
          authenticate,
          verifyCode,
          getSchemaLinks,
          resetPassword,
          confirmPassword,
          resendCode,
          createUser,
          createApp,
          updateApp,
          updateUser,
          createSigner,
          updateSigner,
          updateAll,
          checkEmail,
        }}>
        {children}
      </UserContext.Provider>
    );
  }
}

export { Provider as UserProvider };
